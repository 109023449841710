<template>
<div class="review-component">
    <b-card class="col-lg-12">
        <!-- <div class="row">
            <h4 class="col-lg-5 card-main-title">Request Review &amp; Approval</h4>
            <div class="col-lg-3">
                <b-form-group class="custom-radio" label="Request CFO Approval:">
                    <b-form-radio-group id="CFO-Approval-Required" v-model="Request.IsCFOApprovalRequired">
                        <b-form-radio value="Yes">Yes</b-form-radio>
                        <b-form-radio value="No">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="col-lg-2">
                <button class="text-uppercase btn btn-secondary btn-primary">
                    Edit Request
                </button>
            </div>
            <div class="col-lg-2">
                <div class="transaction-number">
                    <p class="transaction-label">Transaction ID Number</p>
                    <p class="transaction-id">{{Request.TransactionNo}}</p>
                </div>
            </div>
        </div> -->

        <div class="panel-list">
            <div class="panel-body">
                <b-row class="panel-title">
                    <div>
                        Company and Vendor Info
                    </div>
                </b-row>
                <b-row class="panel-content">
                    <b-col cols="lg-3">
                        <label>New Vendor</label>
                        <p>{{Request.NewVendor}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Payment Type</label>
                        <p>{{Request.PaymentType}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Company Name</label>
                        <p>{{Request.CompanyName}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>System</label>
                        <p>{{Request.VendorSystem}}</p>
                    </b-col>
                    <b-col cols="lg-3" v-if="Request.MediaType!=null">
                        <label>Media Type</label>
                        <p>{{Request.MediaType}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>{{VendorCodelabel}}</label>
                        <p v-if="!Request.VendorCode">N/A</p>
                        <p v-else>{{Request.VendorCode}}</p>
                    </b-col>
                </b-row>
            </div>
            <div class="panel-body">
                <b-row class="panel-title">
                    <div> Wire Transfer Info
                    </div>
                </b-row>
                <b-row class="panel-content">
                    <b-col cols="lg-3">
                        <label>Transfer Type</label>
                        <p>{{Request.TransferType}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Debit Company</label>
                        <p>{{Request.DebitCompany}}</p>
                    </b-col>
                    <b-col cols="lg-6">
                        <div class="row expand-row">
                            <div class="col-lg-6">
                                <label>Debit Account Number</label>
                                <p v-if="IsReview==true">{{Request.DebitAccountNumber}}</p>
                                <OmniSelect v-else :showLabel="false" v-model="Request.DebitAccountNumber" :options="DebitAccountNumberOptions" placeholder="Select Account Number" />
                            </div>
                        </div>

                    </b-col>
                    <b-col cols="lg-3">
                        <label>Beneficiary</label>
                        <p>{{Request.Beneficiary}}</p>
                    </b-col>
                      <b-col cols="lg-3">
                        <label>Beneficiary Street Address</label>
                        <p>{{Request.BeneficiaryStreetAddress}}</p>
                    </b-col>

                    <b-col cols="lg-3">
                        <label>Beneficiary Country</label>
                        <p>{{Request.BeneficiaryCountry}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Beneficiary City</label>
                        <p>{{Request.BeneficiaryCity}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Beneficiary Zip code</label>
                        <p>{{Request.BeneficiaryZipCode}}</p>
                    </b-col>

                    <b-col cols="lg-3">
                        <label>Beneficiary Reference</label>
                        <p>{{Request.BeneficiaryReference}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Credit Account Name</label>
                        <p>{{Request.CreditAccountName}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Credit Account Number</label>
                        <p>{{Request.CreditAccountNumber}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Currency</label>
                        <p>{{Request.Currency}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Bank Name</label>
                        <p>{{Request.BankName}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Bank Country</label>
                        <p>{{Request.BankCountry}}</p>
                    </b-col>

                    <b-col cols="lg-3">
                        <label>BIC Type</label>
                        <p>{{Request.BankBICType}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>BIC</label>
                        <p>{{Request.BankBIC}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Invoice Currency</label>
                        <p>{{Request.InvoiceCurrency}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Total Invoice Amount</label>
                        <p>{{Request.TotalInvoiceAmount|formatDecimal}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Requested Transfer Date</label>
                        <p>{{Request.RequestedTransferDate|formatDate}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Paying Bank Charges</label>
                        <p>{{Request.PayingBankCharges}}</p>
                    </b-col>

                    <b-col cols="lg-3">
                        <label>Receiving Bank Charges</label>
                        <p>{{Request.ReceivingBankCharges}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Remittance Advice</label>
                        <p>{{Request.RemittanceAdvice}}</p>
                    </b-col>
                </b-row>
            </div>
            <div class="panel-body" v-if="IsVendoContactInfoRequired">
                <b-row class="panel-title">
                    <div> Vendor Contact Info
                    </div>
                </b-row>
                <b-row class="panel-content">
                    <b-col cols="lg-3">
                        <label>Vendor Contact Name</label>
                        <p>{{Request.VendorName}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Vendor Contact Email</label>
                        <p>{{Request.VendorEmail}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Vendor Contact Phone Number</label>
                        <p>{{Request.VendorPhoneNumber}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>Vendor Contact Role</label>
                        <p>{{Request.VendorRole}}</p>
                    </b-col>
                    <b-col cols="lg-3">
                        <label>How did you obtain this vendor contact info?</label>
                        <p>{{Request.VendorContactInfoObtainedFrom}}</p>
                    </b-col>
                    <b-col cols="lg-3" v-show="IsVendorContactInfoObtainedFromWebsite">
                        <label>Website URL</label>
                        <p>{{Request.VendorInfoObtainedWebsite}}</p>
                    </b-col>
                    <b-col cols="lg-3" v-show="IsVendorContactInfoObtainedFromOMGEmployee">
                        <label>OMG Employee Name</label>
                        <p>{{Request.OMGEmpolyeeName}}</p>
                    </b-col>
                    <b-col cols="lg-3" v-show="IsVendorContactInfoObtainedFromOMGEmployee">
                        <label>OMG Employee Role</label>
                        <p>{{Request.OMGEmpolyeeRole}}</p>
                    </b-col>
                </b-row>
            </div>
            <div class="panel-body">
                <b-row class="panel-title">
                    <div> Paythrough Info
                    </div>
                </b-row>
                <b-row class="panel-content">
                    <b-col cols="lg-2">
                        <label>Paythrough</label>
                        <p>{{Request.Paythrough}}</p>
                    </b-col>
                    <b-col cols="lg-2" v-if="IsPaythrough">
                        <label>Paythrough Bank Name</label>
                        <p>{{Request.PaythroughBankName}}</p>
                    </b-col>
                    <b-col cols="lg-2" v-if="IsPaythrough">
                        <label>Paythrough Bank Country</label>
                        <p>{{Request.PaythroughBankCountry}}</p>
                    </b-col>
                    <b-col cols="lg-2" v-if="IsPaythrough">
                        <label>Paythrough  BIC Type</label>
                        <p>{{Request.PaythroughBICType}}</p>
                    </b-col>
                    <b-col cols="lg-2" v-if="IsPaythrough">
                        <label>Paythrough  BIC</label>
                        <p>{{Request.PaythroughBIC}}</p>
                    </b-col>
                </b-row>
            </div>
            <div class="panel-body">
                <b-row class="panel-title">
                    <div>
                        Support Info
                    </div>
                </b-row>
                <b-row class="panel-content">
                    <b-col :class="{'col-lg-3' : IsReview==true,'col-lg-5' : IsReview!=true}">
                        <label>Attached Files</label>
                        <div class="row-detail-document" v-for="file in Request.Files" :key="file.name">
                            <b-img class="document-icon" :src="fileIcon" alt="Download"></b-img> <span :title="file.Name" class="document-name">{{file.Name}}</span>
                            <b-img @click="downloadItem(file)" title="Download" :src="downloadIcon" alt="Download"></b-img>
                        </div>
                    </b-col>
                    <b-col :class="{'col-lg-3' : IsReview==true,'col-lg-7' : IsReview!=true}">
                        <label>Comments</label>
                        <p v-if="!Request.Notes">N/A</p>
                        <p v-else>{{Request.Notes}}</p>
                    </b-col>
                    <!-- <b-col cols="lg-3" v-if="IsReview==true">
                        <label>Authorized By</label>
                        <p>{{Request.AuthorizedBy|formatEmailToUserName}}</p>
                    </b-col> -->
                    <b-col cols="lg-3" v-if="IsReview==true">
                        <label>Watchers</label>
                        <p>{{Request.WireTransferRequestWatchers|formatWatchers}}</p>
                    </b-col>
                </b-row>
            </div>
            <div class="panel-body" v-if="Request.CFODocuments && Request.CFODocuments.length > 0" >
                <b-row class="panel-title">
                    <div>
                        Additional Documentation Requested by CFO
                    </div>
                </b-row>
                <b-row class="panel-content">
                    <b-col :class="{'col-lg-3' : IsReview==true,'col-lg-5' : IsReview!=true}">
                        <label>Attached Files</label>
                        <div class="row-detail-document" v-for="file in Request.CFODocuments" :key="file.name">
                            <b-img class="document-icon" :src="fileIcon" alt="Download"></b-img> <span :title="file.Name" class="document-name">{{file.Name}}</span>
                            <b-img @click="downloadItem(file)" title="Download" :src="downloadIcon" alt="Download"></b-img>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </b-card>

</div>
</template>

<script>
// import {
//     mapGetters
// } from "vuex";
const moment = require("moment");
export default {
    name: "WireTransferReview",
    data() {
        return {
            editIcon: require("@/assets/Icons/edit.svg"),
            downloadIcon: require("@/assets/Icons/icon-download.svg"),
            fileIcon: require("@/assets/Icons/file-icon-web.webp"),
            DebitCompanyAndAccountNumberMapping: [{
                    label: 'OMD USA',
                    value: 'BAM100Q1'
                },
                {
                    label: 'PHD MEDIA USA',
                    value: 'BAM10610'
                },
                {
                    label: 'OUTDOOR MEDIA GROUP LLC',
                    value: 'BAM50154'
                },
                {
                    label: 'PHD MEDIA - MERCEDES',
                    value: 'BAM50157'
                },
                {
                    label: 'PROMETHEUS MEDIA INC',
                    value: 'BAM50239'
                },
                {
                    label: 'OUTDOOR MEDIA ALLIANCE',
                    value: 'BAM50260'
                },
                {
                    label: 'RFESOLUTION MEDIA INC',
                    value: 'BAM50269'
                },
                {
                    label: 'PHD MEDIA - HEWLETT PACKARD',
                    value: 'BAM50395'
                },
                {
                    label: 'PHD MEDIA - GAP',
                    value: 'BAM50509'
                },
                {
                    label: 'MEDIA SERVICES INC. DBA OMD ENTERTAINMENT INC.',
                    value: 'BAM50553'
                },
                {
                    label: 'OMNET LLC',
                    value: 'BAM50556'
                },
                {
                    label: 'HEARTS AND SCIENCE LLC',
                    value: 'BAM50674'
                },
                {
                    label: 'OMD MITSUBISHI',
                    value: 'BAM50755'
                },
                {
                    label: 'JUMP 450',
                    value: 'BAM50913'
                },
            ]
        }
    },
    props: {
        Request: {
            required: false,
            type: Object,
            default () {
                return {

                }
            }
        },
        ID: {
            type: Number,
            required: false,
            default: 0,
        },
        IsReview: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    computed: {
        IsVendoContactInfoRequired() {
            return this.Request.NewVendor == "Yes";
        },
        VendorCodelabel() {
            return this.Request.PaymentType == 'Client Refund' ? 'Client Code' : 'Vendor code';
        },
        IsVendorContactInfoObtainedFromWebsite() {
            return this.Request && this.Request.VendorContactInfoObtainedFrom == 'Website';
        },
        IsVendorContactInfoObtainedFromOMGEmployee() {
            return this.Request && this.Request.VendorContactInfoObtainedFrom == 'ReferredFromOMGEmployee';
        },
        DebitAccountNumberOptions() {
            return [...new Set(this.DebitCompanyAndAccountNumberMapping.map(val => {
                return {
                    label: val.value,
                    value: val.value
                };
            }))]
        },
        IsPaythrough() {
            return this.Request
                && this.Request.Paythrough
                && this.Request.Paythrough == 'Yes';
        },
        Edit() {
            return false;
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).isValid() ? moment.utc(date).local().format("MM/DD/YYYY") : "-";
        },
        formatDecimal(amount) {
            return amount ? amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-";
        },
        formatEmailToUserName(email) {
            if (email.indexOf("@") > -1) {
                let name = email.substring(0, email.indexOf("@"));
                let values = name.split(".");
                name = "";
                for (var i = 0; i < values.length; i++) {
                    let val = values[i];
                    name += " " + val.substring(0, 1).toUpperCase() + val.substring(1, val.length);
                }
                return name;
            } else {
                return email;
            }
        },
        formatWatchers(watchers) {
            if (watchers == null || watchers.length < 1) {
                return "";
            } else {
                return watchers.map((user) => {
                    if (user.UserName.indexOf("@") > -1) {
                        let name = user.UserName.substring(0, user.UserName.indexOf("@"));
                        let values = name.split(".");
                        name = "";
                        for (var i = 0; i < values.length; i++) {
                            let val = values[i];
                            name += " " + val.substring(0, 1).toUpperCase() + val.substring(1, val.length);
                        }
                        return name;
                    } else {
                        return user.UserName;
                    }
                }).join(",");
            }
        }
    },
    watch: {
        "Request": function () {
            if (this.Request && !this.Request.DebitAccountNumber && this.IsReview != true) {
                let debtAccountNumberExists = this.DebitCompanyAndAccountNumberMapping.findIndex(val => val.label == this.Request.DebitCompany);
                if (debtAccountNumberExists > -1) {
                    this.$set(this.Request, 'DebitAccountNumber', this.DebitCompanyAndAccountNumberMapping[debtAccountNumberExists].value);
                }
            }
        }
    },

    methods: {
        extractUserName(email) {
            if (email.indexOf("@") > -1) {
                let name = email.substring(0, email.indexOf("@"));
                let values = name.split(".");
                name = "";
                for (var i = 0; i < values.length; i++) {
                    let val = values[i];
                    name += " " + val.substring(0, 1).toUpperCase() + val.substring(1, val.length);
                }
                return name;
            } else {
                return email;
            }
        },
        async downloadItem(file) {
            this.$store
                .dispatch("client/DownloadFile", file)
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: file.Extension
                    });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = file.Name;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex}`
                        );
                    }
                });
        },
    }

}
</script>

<style scoped>
.document-icon {
    height: 14px;
    /* width: 11px; */
    /* padding-right: 8px; */
    vertical-align: baseline;
}

.document-name {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    color: #47505a;
    width: 80%;
    /* text-overflow: revert; */
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    /* word-break: unset; */
    height: 10px;
    white-space: nowrap;
}

.card-main-title {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.modal-body p {
    margin: 14px 40px 14px 55px;
    font-family: 'Open Sans' !important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: #4d5161;
}

.add-product-row {
    font-weight: bold;
    padding-left: 0px;
    height: 9px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
    font-weight: 600;
}

.panel-content.row>div>label:first-child,
.panel-content.row>div>.expand-row label {
    color: #A2A9AD !important;
    font-family: "Open Sans" !important;
    font-size: 10px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div> :not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}
</style>
